import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Products from '../../../components/Products';
import ContentImage from '../../../components/ContentImage';
import image_1 from '../../../images/products/partial-discharge-detection-system/uhf.png';
import image_2 from '../../../images/products/partial-discharge-detection-system/hfct.png';

const ProductsPage = () => {
  const { t } = useTranslation('products_partial-discharge-detection-system');
  return (
    <Products pageTitle={t('title')} description={t('description')}>
      <ContentImage image={image_1} title={t('t1.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t1.blurb')}
        </p>
      </ContentImage>
      <ContentImage reverse={true} image={image_2} title={t('t2.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t2.blurb')}
        </p>
      </ContentImage>
    </Products>
  );
};

export default ProductsPage;
